<template>
  <div class="nftmin">
      <Nav></Nav>
      <div class="nftminCon">
          <div class="con0 cursor-pointer" @click="JumpNFT">
            <div></div>
            <div>{{lang('mynft')}}</div>
          </div>
          <div class="con1">
            <p class="p"><b>{{lang('NFTwakaung')}}</b></p>
                <div class="reactivePc"><p>{{lang('wakuangshuoming')}}</p></div>
                <div class="reactiveMob"><p>{{lang('wakuangshuomingqian')}}</p><p>{{lang('wakuangshuominghou')}}</p></div>
          </div>
          <div class="con2">     
            <div class="conNum">
              <div class="a">
                <p><b>{{BigNumberStr(obj.snfttotal,18)}}</b></p>
                <p class="n">{{lang('STFIdiyazonglaing')}}</p>
              </div>
              <div class="a">
                <p><b>21000</b></p>
                <p class="n">{{lang('yichanchujifen')}}</p>
              </div>
              <div class="a">
                <p><b>{{obj.nftNum}}</b></p>
                <p class="n">{{lang('nftduihuanshu')}}</p>
              </div>
            </div>
          </div>
          <div class="con3">
            <div class="con3L">
                <div class="jfImg"></div>
                <div class="jf">{{lang('jifen')}}</div>
                <div class="jfNum">{{BigNumberStr(exchangeNum,18,3)}}</div>
                <div class="c jf">{{lang('daiduihuajifen')}}</div>
                <div class="jfbtn cursor-pointer" @click="showRedeem">{{lang('duihuanNFT')}}</div>
            </div>
            <div class="con3R con3L">
                <div class="jfBg"></div>
                <div class="jf">sTFI</div>
                <div class="jfNum">{{BigNumberStr(obj.pleYourself,18)}}</div>
                <div class="c jf">{{lang('yizhiyaSTFI')}}</div>
                <div class="jfbtn cursor-pointer" v-if="getAllowanceNum <=0" @click="getApprove">{{lang('a_shouquan')}}</div>
                <div class="con4 cursor-pointer" v-if="getAllowanceNum >0">
                  <div class="btnf"  @click="showRede">{{lang('a_shuhui')}}</div>
                  <div class="btnz" @click="showPledge">{{lang('zhiya')}}</div>
                </div>
            </div>
          </div>
          
           <div class="con5">
            <p>{{lang('NFTwakaungguize')}}</p>
            <div class="cont">
              <p>1.{{lang('NFTwakaungguizeone')}}</p>
              <p>2.{{lang('NFTwakaungguizetwo')}}</p>
              <p>3.{{lang('NFTwakaungguizethree')}}</p>
            </div>
          </div>
      </div>
  </div>
</template>
<script>
    import Nav from '../../components/Nav/Index'
    import { lang,Toast } from "../../tools";
    import * as metaMaskTools from "../../tools/chain";
    import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
    import { busEvent } from "../../components/busEvent.js"

  export default {
    name: 'nftmining',
    components: {Nav},
    mixins: [mixin1, mixin2, mixin3],
    created(){
    let this_ = this
    busEvent.$on('getBusPle',function(val){
      this_.getMiningInfo();
    })
     busEvent.$on('getBusPleRede',function(val){
      this_.getMiningInfo();
    })
    busEvent.$on('getBusRedeem',function(val){
      this_.getMiningInfo();
    })
  },
    computed: {
    account() {
      return this.$store.state.accounts[0];
    },
    },
    beforeDestroy() {
    clearInterval(this.interval);
    },
    mounted() {
      this.interval = setInterval(() => {
      this.getExchangeXnFT();
    }, 5000);
    },
    data() {
        return {
          obj:{
            snfttotal:0,
            nftNum:0,
            pleYourself:0,
            outputPoints:0
          },
          getAllowanceNum:0,
          exchangeNum:0,
          interval: null,
            
        }
    },
    methods: {
      initPage() {
        this.getMiningInfo()
        this.getAllowance()
        this.getExchangeXnFT()
      },
      initData() {
        this.getExchangeXnFT()
      },
      onRefresh(accounts) {
         this.initPage()
      },
      async getExchangeXnFT() {
        var res = await metaMaskTools.getExchangeXnFT(this.account);
        this.exchangeNum=res
      },
      async getMiningInfo() {
        var res = await metaMaskTools.getMiningInfo(this.account);
        this.obj=res
      },
      async getAllowance() {
          var res=await metaMaskTools.getSNFTAllowance(this.account);
          this.getAllowanceNum=res
        },
      async getApprove() {
          await metaMaskTools.getSNFTApprove(this.account);
          Toast(this.lang('a_shouquanchenggong'))
          this.getAllowance()
        },
      showRedeem(){
        this.$store.commit('setState', {
          redeemShow: true
        })
      },
      showPledge(){
        this.$store.commit('setState', {
          pledgeShow: true
        })
      },
      showRede() {
        this.$store.commit('setState', {
          pledgeRedeShow: true
        })
      },
      JumpNFT() {
        this.$router.push('/MyNft');
      },
      
      lang(key){
          return lang(key);
      },
        
    },
  }

</script>

<style scoped lang="less">
.cursor-pointer{
  cursor: pointer;
}
@media (min-width: 769px) {
  .nftminCon {
    box-sizing: border-box ;
    width: 100%;
    padding: 110px 240px 0 280px;
      .con0 {
         z-index: 40;
          position: fixed;
          top: 180px;
          right: 40px;
          height: 28px;
          line-height: 25px;
          border-radius: 14px;
          border: 1px solid #fff;
          background-color: #152e55;
          color: #fff;
          padding: 0 10px;        
          div:nth-of-type(1) {
            width: 12px;
            height: 12px;
            background: url('../../assets/img/xuan.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            display: inline-block;
            vertical-align:middle;
            margin-right: 6px;
          }
          div:nth-of-type(2) {
            display: inline-block;
            font-size: 12px;
            vertical-align:middle;
            margin-top: -2px;
          }
          
      }
      .con1 {
            z-index: 40;
            position: fixed;
            margin-top: 60px;
            color: #fff;
            p:nth-of-type(2) {
              font-size: 12px;
              margin-top: 8px;
            }
      }
      .con2 {
    margin-top: 140px;
    width: 100%;
    
    p {
      font-size: 15px;
      color: #fff;
    }
    .conNum {
      border-radius:10px ;
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
      .a {
        text-align: center;
        width: 33.3%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(3) {
          border-right: 0;
      }
    }
      }
      .con3 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          color: #fff;
        .con3L {
            display: flex;
            flex:1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 181.5px;
            margin-right: 20px;
            background-color: rgba(#0F111F,.4);
            border-radius:10px ;
          .jfImg {
              width: 40px;
              height: 40px;
              background: url('../../assets/img/jf.png') no-repeat;
              background-size: 100% 100%;
              margin-bottom: 10px;
              
          }
          .jf {
              color: rgba(#ccc,.4);
              font-size: 12px;
              margin-bottom: 10px;
          }
          .jfNum {
              margin-bottom: 5px;
          }
          .jfbtn {
              height: 22.5px;
              line-height: 22.5px;
              background: linear-gradient(#0077c5, #0045bb);
              text-align: center;
              padding: 0 15px;
              border-radius:10px ;
              font-size: 12px;
          }
        }
        .con3R {
          flex:1;
          margin-right: 0;
            .jfBg {
                width: 40px;
                height: 40px;
                border-radius:50% ;
                background: url('../../assets/img/stfi.png') no-repeat;
                background-size: 100% 100%;
                margin-bottom: 10px;
            }
        }
        
      }
      .con4 {
          display: flex;
          justify-content: center;
          // margin-top: 20px;
          width: 100%;
          color: #fff;
          font-size: 12px;
        .btnf {
            width: 75px;
            height: 22.5px;
            background: linear-gradient(rgba(#0077c5,.4), rgba(#0045bb,.4));
            border-radius: 10px;
            line-height: 22.5px;
            text-align: center;
            color: #0077C5;
            border: 1px solid #0077c5;
        }
        .btnz {
            width: 75px;
            height: 22.5px;
            background: linear-gradient(#0077c5, #0045bb);
            margin-left: 20px;
            border-radius: 10px;
            line-height: 22.5px;
            text-align: center;
        }
      }
      .con5 {
        margin-top: 15px;
        width: 100%;
        color: #fff;
        font-size: 12px;
        .cont {
          height: 100px;
          padding: 14.5px 21px;
          background-color: rgba(#0F111F,.4);
          margin-top: 20px;
          border-radius: 10px;
          p {
            line-height: 24px;
          }
        }
      }
  }
}

@media (max-width: 768px){
    .nftminCon {
    box-sizing: border-box ;
    width: 100%;
    padding: 10px 20px 0 ;
      .con0 {
          margin-top: 20px;
          text-align: center;
          width: 0.8rem;
          height: 28px;
          line-height: 25px;
          border-radius: 14px;
          border: 1px solid #fff;
          background-color: #152e55;
          color: #fff;
          padding: 0 5px;        
          div:nth-of-type(1) {
            width: 0.12rem;
            height: 12px;
            background: url('../../assets/img/xuan.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            display: inline-block;
            vertical-align:middle;
            margin-right: 6px;
          }
          div:nth-of-type(2) {
            display: inline-block;
            font-size: 12px;
            vertical-align:middle;
            margin-top: -2px;
          }
          
      }
      .con1 {
            box-sizing: border-box ;
            width: 100%;
            margin-top: 20px;
            color: #fff;
            font-size: 12px;
            .p {
              font-size: 16px;
            } 
            .reactiveMob {
              margin-top: 15px;
              p {
                 display: inline-block;
              }
            }
      }
      .con2 {
        width: 100%;
        margin-top: 20px;  
        p {
          font-size: 12px;
          color: #fff;
        }
        .conNum {
          border-radius:10px ;
          display: flex;
          align-items: center;
          height: 80px;
          background: linear-gradient(#0077c5, #0045bb);
          margin-top: 20px;
          .a {
            text-align: center;
             width: 33.3%;
            border-right: 1px solid #ccc;
            .n {
              margin-top: 8px;
            }
          }
          .a:nth-of-type(3) {
              border-right: 0;
          }
        }
      }
      .con3 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          font-size: 12px;
          color: #fff;
        .con3L {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc((100% - 30px) / 2);
            height: 181.5px;
            margin-right: 10px;
            background-color: rgba(#0F111F,.4);
            border-radius:10px ;
          .jfImg {
              width: 0.4rem;
              height: 40px;
              background: url('../../assets/img/jf.png') no-repeat;
              background-size: 100% 100%;
              margin-bottom: 10px;
              
          }
          .jf {
              color: rgba(#ccc,.4);
              font-size: 12px;
              margin-bottom: 10px;
          }
          .jfNum {
              margin-bottom: 5px;
          }
          .jfbtn {
              height: 22.5px;
              line-height: 22.5px;
              background: linear-gradient(#0077c5, #0045bb);
              text-align: center;
              padding: 0 15px;
              border-radius:10px ;
              font-size: 12px;
          }
        }
        .con3R {
          margin-right: 0;
            .jfBg {
                width: 0.4rem;
                height: 40px;
                border-radius:50% ;
                background-color: #808080;
                margin-bottom: 10px;
            }
        }
        
      }
      .con4 {
          display: flex;
          margin-top: 20px;
          width: 100%;
          color: #fff;
          font-size: 12px;
        .btnf {
            width: 0.55rem;
            height: 22.5px;
            background: linear-gradient(rgba(#0077c5,.4), rgba(#0045bb,.4));
            border-radius: 10px;
            line-height: 22.5px;
            text-align: center;
            color: #0077C5;
            border: 1px solid #0077c5;
        }
        .btnz {
            width: 0.55rem;
            height: 22.5px;
            background: linear-gradient(#0077c5, #0045bb);
            border-radius: 10px;
            line-height: 22.5px;
            text-align: center;
        }
      }
      .con5 {
        margin-top: 15px;
        width: 100%;
        color: #fff;
        font-size: 12px;
        .cont {
          height: 100px;
          padding: 14.5px 21px;
          background-color: rgba(#0F111F,.4);
          margin-top: 15px;
          border-radius: 10px;
          p {
            line-height: 20px;
          }
        }
      }
  }
  }

</style>
